.required {
    color:red;
}

form label {
    display:block;
}

.errorMessage {
    color:red;
}